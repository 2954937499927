import './App.css';
import 'antd/dist/reset.css';
import cookie from "react-cookies";


import { Input,Modal ,message} from 'antd';
import axios from "axios";
import {SearchOutlined,CloseCircleOutlined,CheckCircleOutlined} from '@ant-design/icons';
import {useState,useEffect} from "react";
function App() {
    const [isActive, setIsActive] = useState(1)
    const [pageName, sePageName] = useState('')
    const [isActiveCard, setisActiveCard] = useState(1)
    const [cardList, setCardList] = useState([])
    const [pagedata, setPagedata] = useState({})
    const [questionPageList, setqQestionPageList] = useState([])
    const [hotOptions, setHotOptions] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [codeUrl, setCodeUrl] = useState('');
    useEffect(() => {
        const fetchDatab = async () => {
            const response = await fetch('/pagedata.json');
            const json = await response.json();
            setPagedata(json)
            setCardList(json.cardList)
            setqQestionPageList(json.questionList)
        };
        const fetchData = async () => {
            const response = await axios.get('/api/open_wechat/check_name');
            setHotOptions(response.data.data.hot_search)
            setCodeUrl(response.data.data.pc_qrcode)
        };
        const fetchDataa = async () => {
            const response = await axios.get('/api/index/index');
            sePageName(response.data.data.site_name)
            document.title = response.data.data.site_name

        };

        fetchData();
        fetchDataa();
        fetchDatab();
    }, []);
    const [open, setOpen] = useState(false);
    const [Modaldata, setModaldata] = useState({});
    const showModal = async () => {
        if(inputValue === ''){
            message.warning('请输入查询内容！');
            return
        }
        if(cookie.load("times")&&cookie.load("times")>=3){
            let ResData ={
                icon:2,
                code:codeUrl,
                desc:pagedata.useup
            }
            setOpen(true);
            setModaldata(ResData)
            return
        }
        const config = {
            method: 'post',
            url: '/api/open_wechat/check_name_put',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                name: inputValue
            }
        };
        const response = await axios.request(config);
        if(response.data.status===400){
            let ResData ={
                icon:2,
                code:codeUrl,
                desc:pagedata.err
            }
            setOpen(true);
            setModaldata(ResData)
        }
        if(response.data.status===200){
            let ResData ={
                icon:1,
                code:codeUrl,
                desc:pagedata.success
            }
            setOpen(true);
            setModaldata(ResData)
        }
        if(cookie.load("times")){
            let times= Number(cookie.load("times"))
            let cookieTime = new Date(new Date().getTime + 24 * 3600 * 1000);
            cookie.save("times", times+1, {expires: cookieTime});
        }else {
            let cookieTime = new Date(new Date().getTime + 24 * 3600 * 1000);
            cookie.save("times", 1, {expires: cookieTime});
        }
    };
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const hideModal = () => {
        setOpen(false);
    };
   const currentHotSize = (name)=>{
       setInputValue(name)
   }
    const changeActiveCard = (e) => {
        setisActiveCard(e)
    }
 const changeActive = (e) => {
     setIsActive(e)
     if(e===1){
         setqQestionPageList(pagedata.questionList)
     }
     if(e===2){
         setqQestionPageList(pagedata.questionListb)
     }

 }
  return (
    <div className="App">
        <div className="header">
          <div className="title">小程序名称查询</div>
          <div className="bubble">
            <div className="bubblecontent">
              <div className={["bubbleItem", isActiveCard === 1?"abubblective":null].join(' ')} onClick={changeActiveCard.bind(this,1)} >企业微信小程序</div>
              <div className={["bubbleItem", isActiveCard === 2?"abubblective":null].join(' ')} onClick={changeActiveCard.bind(this,2)} >个体微信小程序</div>
              <div className={["bubbleItem", isActiveCard === 3?"abubblective":null].join(' ')}  onClick={changeActiveCard.bind(this,3)} >个人微信小程序</div>
            </div>
          </div>
          <div className="searchBox">
            <div className='searchBoxMain'>
              <div style={{flex:1}}><Input  value={inputValue} placeholder="请输入小程序名称" onChange={handleInputChange} /></div>
              <div className="serchBtn" onClick={showModal}><SearchOutlined style={{fontSize:"16px"}} />&nbsp;搜   索</div>
            </div>
          </div>
          <div className="hotsearch">
            <div className="bubblecontent">
              <div className="hotSearchleft">
                  <img src='img/hot.png' alt="图片"/>
              </div>
              <div className="hotSearch">
                {hotOptions.map((item,index) => {
                  return <div key={index}  className="hotSearchItem">
                      <div className="hotSearchItemMain" onClick={currentHotSize.bind(this,item.name)}>{item.name} </div>
                  </div>;
                })}

              </div>
            </div>
          </div>
        </div>
      <div className="cardBox">
        <div className="cardMian">
          {cardList.map((item,index) => {
            return <div key={index} className="cardItem">
                <div className="icon">
                    <img src={item.icon} alt="图片"/>
                </div>
                <div className="content">
                  <div className="name">{item.name} </div>
                  <div className="describe">查询同行是否注册</div>
                </div>
            </div>;
          })}
        </div>
      </div>
      <div className="question">
         <div className="question_main">

                 <img className="question_mainimg1" src="img/quest.png" alt="图片加载失败"/>
             <img className="question_mainimg2" src="img/quest.png" alt="图片加载失败"/>
             <div>
                 <div className="titless">常见问题</div>
                 <div className="title2">我们提供一些在以往客户注册中遇到较多疑问</div>
                 <div className="tabCard">
                     <div className="tabItem" onClick={changeActive.bind(this,1)} style={{borderBottom:(isActive === 1)?'4px solid #004AFD':'4px solid #FFFFFF'}}>注册相关</div>
                     <div className="tabItem" onClick={changeActive.bind(this,2)} style={{borderBottom:(isActive === 2)?'4px solid #004AFD':'4px solid #FFFFFF'}}>增值服务</div>
                 </div>
             </div>
             <img className="question_mainimg3 " src="img/quest.png" alt="图片加载失败"/>
             <img className="question_mainimg4" src="img/quest.png" alt="图片加载失败"/>
         </div>
      </div>
        <div className="questionListBox">
            <div className="questionList">
                {
                    questionPageList.map((item,index)=>{
                        return <div className="item" key={index}>
                            <div className="itemName">{item.name}</div>
                            <div className="itemdasc">{item.desc}</div>
                        </div>
                        }
                    )
                }

            </div>

        </div>
        <div className="footer">{pageName} 版权所有.</div>
        <Modal title=""  open={open} onOk={hideModal} centered={true}  onCancel={hideModal} footer={null} >
           <div className="modalContent">
               <div className="titleee">  {Modaldata.icon === 2 ? <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' ,marginRight: "10px"}} /> :<CheckCircleOutlined style={{ fontSize: '20px', color: 'green' ,marginRight: "10px"}} />}{Modaldata.desc}</div>
               <div className="code">
                   <img style={{ width: '100%', height: '100%'}} src={Modaldata.code} alt=""/>
               </div>
               <div className="desc">{pagedata.tips}</div>
           </div>
        </Modal>
    </div>
  );
}

export default App;
